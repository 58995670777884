import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import parse from "html-react-parser"

const POPULAR_POSTS_QUERY = graphql`
  query GET_POPULAR_POSTS {
    allWpPost(
      limit: 3
      filter: { tags: { nodes: { elemMatch: { name: { eq: "Popular" } } } } }
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        uri
        title
        date(formatString: "MMMM DD, YYYY")
      }
    }
  }
`

const Popular = props => {
  return (
    <>
      <h1 className="h3">Popular</h1>
      <hr className="header-hr" />
      <StaticQuery
        query={POPULAR_POSTS_QUERY}
        render={({ allWpPost: { nodes: posts } }) => {
          return posts.map(post => (
            <div>
              <Link to={post.uri}>
                <h2 className="h5">{parse(post.title)}</h2>
              </Link>
              <p>{post.date}</p>
            </div>
          ))
        }}
      />
      <div className="wp-block-buttons is-content-justification-center">
        <div className="wp-block-button">
          <Link
            to="/tag/popular/"
            aria-label="See more popular episodes"
            className="wp-block-button__link has-white-color has-poi-blue-background-color has-text-color has-background"
          >
            See More
          </Link>
        </div>
      </div>
    </>
  )
}

export default Popular
