import React from "react"
import { Link, graphql } from "gatsby"
import MostRecent from "../components/mostRecent"
import Popular from "../components/popular"
import Newsletter from "../components/newsletter"
import ITunes from "../images/itunes-logo-poi.svg"
import Spotify from "../images/spotify-logo-poi.svg"
import Stitcher from "../images/stitcher-logo-poi.svg"
import CFImark from "../images/CFImark2017.svg"

import {
  FaGavel,
  FaChurch,
  FaAtom,
  FaBrain,
  FaEnvelope,
  FaFacebookF,
  FaTwitter,
} from "react-icons/fa"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Page = ({ data: { page } }) => {
  return (
    <Layout>
      <Seo title={page.title} description={page.excerpt} />
      <main id="main" className="site-main" role="main">
        <article
          id={"post-" + page.databaseId}
          className="page type-page status-publish hentry home"
          itemScope
          itemType="http://schema.org/Article"
        >
          <div className="entry-content">
            <div
              className="wp-block-columns wrap-100-m"
              style={{ position: "relative" }}
            >
              <div
                id="most-recent"
                className="wp-block-column has-white-color has-poi-blue-background-color has-text-color has-background"
              >
                <MostRecent />
              </div>
              <div
                id="popular"
                className="wp-block-column has-poi-blue-color has-white-background-color has-text-color has-background"
              >
                <Popular />
              </div>
            </div>

            <div className="wp-block-columns has-poi-gold-background-color has-white-color has-background">
              <div id="podcast-apps" className="wp-block-column">
                <h1 className="h3">
                  Point of Inquiry is available on all of your favorite podcast
                  apps.
                </h1>
                <p>
                  We are available on multiple podcast platforms to make it
                  easier and simpler for you to enjoy every episode. Click on
                  your app of choice and subscribe.
                </p>
                <div className="app-logos">
                  <a
                    href="https://itunes.apple.com/us/podcast/point-of-inquiry/id107134018?mt=2"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <ITunes
                      width={65}
                      height={65}
                      className="mx-3"
                      alt="iTunes Logo / Apple Music Logo"
                    />
                  </a>
                  <a
                    href="https://www.stitcher.com/podcast/point-of-inquiry"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Stitcher
                      width={139}
                      height={65}
                      className="mx-3"
                      alt="Stitcher Logo"
                    />
                  </a>
                  <a
                    href="https://open.spotify.com/show/53S5tygwxoJPVc8qB4p3xN"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Spotify
                      width={65}
                      height={65}
                      className="mx-3"
                      alt="Spotify Logo"
                    />
                  </a>
                </div>
              </div>
            </div>

            <div
              className="wp-block-columns has-background cfi-plug are-vertically-aligned-center"
              style={{ backgroundColor: "#fafafa" }}
            >
              <div
                className="wp-block-column wrap-25-m"
                style={{ flexBasis: "25%" }}
              >
                <CFImark
                  width={100}
                  height={90}
                  alt="Center for Inquiry Logo"
                />
              </div>

              <div
                className="wp-block-column wrap-75-m"
                style={{ flexBasis: "75%" }}
              >
                <p>
                  <em>Point of Inquiry</em> is brought to you by the{" "}
                  <a
                    href="https://centerforinquiry.org/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Center for Inquiry
                  </a>{" "}
                  a 501(c)(3) charitable nonprofit organization. The Center for
                  Inquiry strives to foster a secular society based on reason,
                  science, freedom of inquiry, and humanist values.
                </p>
              </div>
            </div>
            <div className="wp-block-columns">
              <div
                id="promo-product"
                className="wp-block-column has-poi-blue-color has-white-background-color has-text-color has-background"
              >
                <div id="promo-text">
                  <h1 className="h3">Contact Us</h1>
                  <hr className="header-hr" />
                  <p>
                    <FaEnvelope /> |{" "}
                    <a href="mailto:feedback@pointofinquiry.org">Email</a>
                  </p>
                  <p>
                    <FaTwitter /> |{" "}
                    <a
                      aria-label="Twitter"
                      href="mailto:https://twitter.com/pointofinquiry"
                    >
                      @pointofinquiry
                    </a>
                  </p>
                  <p>
                    <FaFacebookF /> |{" "}
                    <a href="https://www.facebook.com/pointofinquiry/">
                      Facebook
                    </a>
                  </p>
                </div>
              </div>
              <div
                id="subscribe"
                className="wp-block-column has-white-color has-poi-blue-background-color has-text-color has-background"
              >
                <h1 className="h3">
                  Stay informed on the most interesting topics in science,
                  religion, and politics. No needless spam or wasteful emails.
                </h1>
                <hr className="header-hr" />
                <Newsletter />
              </div>
            </div>
            <div
              id="tags"
              className="wp-block-columns no-padding nowrap-m wrap-50-s"
            >
              <div className="wp-block-column has-background has-poi-gold-background-color">
                <Link className="home-tags col-6 col-md-3" to="/tag/law/">
                  <FaGavel />
                  <h2 className="h5">Law</h2>
                </Link>
              </div>
              <div className="wp-block-column has-background has-poi-gold-background-color">
                <Link className="home-tags col-6 col-md-3" to="/tag/religion/">
                  <FaChurch />
                  <h2 className="h5">Religion</h2>
                </Link>
              </div>
              <div className="wp-block-column has-background has-poi-gold-background-color">
                <Link className="home-tags col-6 col-md-3" to="/tag/science/">
                  <FaAtom />
                  <h2 className="h5">Science</h2>
                </Link>
              </div>
              <div className="wp-block-column has-background has-poi-gold-background-color">
                <Link
                  className="home-tags col-6 col-md-3"
                  to="/tag/skepticism/"
                >
                  <FaBrain />
                  <h2 className="h5">Skepticism</h2>
                </Link>
              </div>
            </div>
          </div>
        </article>
      </main>
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query GET_HOME_PAGE {
    page: wpPage(isFrontPage: { eq: true }) {
      title
      content
      databaseId
      uri
      slug
      smartcrawlSeo {
        metaDesc
        title
        opengraphTitle
        opengraphDescription
        opengraphImage {
          mediaItemUrl
        }
        twitterTitle
        twitterDescription
        twitterImage {
          mediaItemUrl
        }
      }
      featuredImage {
        node {
          mediaItemUrl
        }
      }
    }
  }
`
