import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import parse from "html-react-parser"

const LATEST_POST_QUERY = graphql`
  query GET_RECENT_POST {
    allWpPost(limit: 1, sort: { order: DESC, fields: date }) {
      nodes {
        title
        excerpt
        date(formatString: "MMMM DD, YYYY")
        uri
      }
    }
  }
`

const MostRecent = props => {
  return (
    <StaticQuery
      query={LATEST_POST_QUERY}
      render={({ allWpPost: { nodes: posts } }) => {
        return posts.map(post => (
          <>
            <h1 className="h3">Our Most Recent Episode</h1>
            <hr className="header-hr" />
            <h2 className="h5">
              <Link to={post.uri} style={{ color: "white" }}>
                {parse(post.title)}
              </Link>
            </h2>

            <p>{post.date}</p>
            <hr />
            {parse(post.excerpt)}
            <div className="wp-block-buttons is-content-justification-center">
              <div className="wp-block-button">
                <Link
                  to={post.uri}
                  className="wp-block-button__link has-poi-blue-color has-poi-gold-background-color has-text-color has-background"
                >
                  Listen Now
                </Link>
              </div>
            </div>
          </>
        ))
      }}
    />
  )
}

export default MostRecent
